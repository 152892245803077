/* Carousel styles */
.team-carousel {
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  
  .team-carousel .team-item {
    flex: 0 0 33.33%; /* Show 3 items at a time */
    max-width: 33.33%;
    transition: transform 0.5s ease-in-out;
  }
  
  .team-item {
    box-sizing: border-box;
    padding: 0 15px;
  }
  
  /* Hide the carousel navigation initially */
  .team-carousel:hover .carousel-nav {
    display: block;
  }
  
  .carousel-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    display: none;
  }
  
  .carousel-nav button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 2em;
  }
  